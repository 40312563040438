body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.TVChartContainer {
  height: 100vh;
}

/* dark theme */
@media screen and (max-width: 991px) {
  .dark .properties-tabs a.properties-tabs-label {
    padding: 15px 0 7px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .dark #tv_chart_container {
    background: #454545;
  }

  .dark ._tv-dialog-content .alert-dialog-content,
  ._tv-dialog-content .main-properties {
    padding: 10px 15px;
  }

  .dark .properties-tabs {
    padding: 0 15px;
  }

  .dark ._tv-dialog {
    font-size: 12px;
  }

  .dark .property-page td {
    height: 20px;
    padding: 2px;
  }

  .dark ._tv-dialog-content .dialog-buttons {
    margin-top: 0;
  }
}
.dark #library-container {
  border: none;
}
.dark .chart-controls-bar {
  background: #2f302a;
}
.dark .header-chart-panel {
  background: #454545;
}
.dark .tv-side-toolbar {
  background: #2f302a;
  border-color: #7d7d7d;
}
.dark .tv-side-toolbar .button,
.tv-side-toolbar .button .main {
  background: #2f302a !important;
}
.dark .tv-side-toolbar .button.selected {
  border-color: #7d7d7d !important;
}
.dark #chart-area {
  background: #2f302a;
}
.dark .chart-container.active {
  border-color: #2f302a;
}
.dark .group.space-single {
  background: #454545;
}
.dark .group.space-single .button {
  background: #2f302a !important;
  border-color: #454545;
}
.dark .group input {
  background: #2f302a !important;
  border-color: #454545;
  color: #ddd !important;
}
.dark .group span {
  background: #2f302a !important;
  border-color: #454545;
  color: #ddd !important;
}
.dark .on-widget .getimage .icon {
  fill: #7d7d7d;
}
.dark .loading-indicator {
  background: #2f302a !important;
}
.dark .symbol-edit-widget .symbol-edit-wrap.expanded {
  box-shadow: #333 0 0 10px 10px;
}

/* normal theme */
@media screen and (max-width: 991px) {
  .properties-tabs a.properties-tabs-label {
    padding: 15px 0 7px;
    margin-left: 10px;
    margin-right: 10px;
  }

  ._tv-dialog-content .alert-dialog-content,
  ._tv-dialog-content .main-properties {
    padding: 10px 15px;
  }

  .properties-tabs {
    padding: 0 15px;
  }

  ._tv-dialog {
    font-size: 12px;
  }

  .property-page td {
    height: 20px;
    padding: 2px;
  }

  ._tv-dialog-content .dialog-buttons {
    margin-top: 0;
  }
}

.notes {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #00000078;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notes .content {
  background: #0d6ffc;
  padding: 20px;
  padding-left: 14px;
  padding-right: 14px;
  color: white;
  border-radius: 8px;
  text-align: right;
  font-size: 14px;
  display: flex;
}

.click {
  width: 50px;
}

.notes button {
  background: white;
  border-radius: 4px;
  outline: none;
  border: none;
  padding: 4px;
  margin-top: 10px;
}
